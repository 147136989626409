* {
  font-family: "Open Sans";
}
body img {
  image-rendering: -webkit-optimize-contrast;
}
svg {
  image-rendering: -webkit-optimize-contrast;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
::-webkit-scrollbar {
  width: 10;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.justify-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.item-flex-start {
  align-items: flex-start;
}
.item-flex-end {
  align-items: flex-end;
}
.justify-flex-end {
  justify-content: flex-end;
}
.tenant-detail-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.tenant-details-wrapper {
  padding: 28px;
  background: #f5f5f5;
  border-radius: 4px;
  height: 100%;
}
.tenant-card {
  height: 100%;
  padding: 20px 36px 50px;
  background: #fff;
  box-shadow: 0px 3px 15px 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.tenant-form-wrapper {
  border: 1px solid #d9d9d9;
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 1.5rem 2.5rem;
}
.table-style {
  overflow: hidden;
  position: relative;
  font-size: 15px;
  line-height: 1.3;
}
.table-style th {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
}

.application-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid #d9d9d9;
}
.application-header > div {
  padding: 5px 15px;
}
.application-header > div:nth-child(1) {
  max-width: 35%;
  flex-basis: 35%;
}
.application-header > div:nth-child(2) {
  max-width: 65%;
  flex-basis: 65%;
}
.application-header-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.application-header-actions.grid-layout {
  min-height: 54px;
}
.application-header-actions.grid-layout h3 {
  margin: 0;
}
.searchFormWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: -5px;
  margin-right: -5px;
}
.searchFormWrapper > div {
  padding: 10px 5px;
  position: relative;
}
.searchFormWrapper .searchBtn {
  min-width: 100px;
}
.searchFormWrapper input {
  min-width: 360px;
}
.searchFormWrapper .clearBtn {
  position: absolute;
  right: 8px;
  z-index: 9;
  top: 16px;
  opacity: 0.5;
}
.tenant-content-wrapper {
  display: flex;
  padding: 30px 15px;
}
.tenant-content-wrapper > div {
  padding: 0 10px;
}
.tenant-content-wrapper > div:nth-child(1) {
  min-width: 300px;
  width: 100%;
  max-width: 450px;
}
.tenant-content-wrapper > div:nth-child(2) {
  width: 100%;
}

.application-action-bar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: -5px;
  margin-left: -5px;
  padding-top: 10px;
  margin-bottom: 5px;
}
.application-action-bar > div {
  padding: 0 5px;
}
.downloads button,
.related-quotes button {
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #666;
}
.downloads button svg {
  font-size: 20px;
}
.downloads-options .MuiPopover-paper,
.related-quotes .MuiPopover-paper {
  box-shadow: 0px 5px 10px 0px rgb(33 33 33 / 15%);
}
.downloads-options .MuiPopover-paper li,
.related-quotes .MuiPopover-paper li {
  font-size: 14px;
}
.filter-dropdown {
  top: 55px;
  right: 0;
  width: 100%;
  padding: 1rem;
  z-index: 600;
  position: absolute;
  max-width: 280px;
  background: #fff;
  box-shadow: 0px 4px 20px 2px rgb(0 0 0 / 15%);
  border-radius: 4px;
  z-index: 1100;
}
.filter-group {
  margin-bottom: 0.4rem;
}
.filter-group h5 {
  margin: 0px 0px 10px 0px;
}
.filter-group ul {
  margin: 0;
  padding: 0;
}
.filter-option-item {
  cursor: default;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  padding: 0.2rem 0;
  margin-bottom: 0.5rem;
  font-family: "Open Sans", sans-serif;
  transition: all 0.4s;
  border-radius: 4px;
  padding-left: 5px;
  line-height: 1rem;
}
.filter-option-item:not(.option-sort) {
  cursor: pointer;
  margin-bottom: 0;
}
.filter-option-item.option-sort .icon {
  cursor: pointer;
}
.filter-group ul li:last-child {
  margin-bottom: 0;
}
.filter-option-item:hover {
  background-color: #f7f7f7;
}
.filter-option-item .value {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
}
.filter-option-item .icon {
  padding-right: 5px;
  opacity: 0.5;
  display: flex;
}
.filter-option-item.active .status svg {
  opacity: 1;
}
.filter-option-item .value span:first-child {
  font-size: 13px;
  font-weight: 600;
}
.filter-option-item.option-sort .value {
  cursor: pointer;
  line-height: 1.8;
}
.filter-option-item .value span:last-child {
  font-size: 11px;
  font-weight: 400;
  color: #999999;
}
.filter-option-item .status svg {
  opacity: 0;
}
.filter-option-item svg {
  font-size: 23px;
}
.filters-wrapper {
  display: flex;
  margin: 0 -3px;
}
.filters-wrapper > .item {
  padding: 0 3px;
}
.filters-wrapper .filter-handle {
  display: flex;
  align-items: center;
  color: #424242;
  cursor: default;
  display: flex;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  align-items: center;
  font-weight: 600;
  border-radius: 4px;
  background-color: #f5f5f5;
  height: 38px;
  width: 72px;
  transition: all 0.4s;
}
.col-layout-wrapper .filters-wrapper .filter-handle {
  font-size: 13px;
  height: 30px;
  width: 75px;
  background-color: #ebebeb;
  border-radius: 2px;
}
.filters-wrapper .filter-handle:hover {
  background-color: #cecece;
}
.filters-wrapper.new-layout {
  margin: 0;
  width: 100%;
  justify-content: space-between;
}
.filter-handle svg {
  font-size: 18px;
  margin-right: 3px;
}
.filter-handle.filterActive {
  background-color: #bfbfbf;
}
.filter-dropdown-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #c0c0c000;
  z-index: 999;
}
.filterBtnGroup {
  margin-top: 10px;
}
.filterBtnGroup button {
  font-size: 13px;
  padding: 2px;
  line-height: 23px;
}
.application-item {
  cursor: pointer;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  display: flex;
  padding: 0.5rem 0.75rem 0.3rem;
  box-shadow: 0px 2px 10px rgb(33 33 33 / 15%);
  transition: all 0.5s;
  align-items: flex-start;
  border-radius: 4px;
  justify-content: flex-start;
  background-color: #ffffff;
  min-height: 65px;
}
.application-item.grid-layout {
  min-height: 74px;
}
.application-item:hover {
  box-shadow: none;
}
.application-item .circle-wrapper {
  min-width: 35px;
  flex-grow: 0;
  flex-basis: 0;
  padding-right: 10px;
  text-align: center;
  position: relative;
}
.application-item .circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  text-transform: uppercase;
}
.application-item .info-wrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
}
.application-item .info-wrapper .info {
  flex-grow: 1;
  padding-right: 10px;
  overflow: hidden;
}
.application-item .info-wrapper .title {
  margin-top: 0;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
}
.application-item .info-wrapper .detail {
  font-size: 0.8rem;
  color: #999999;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.application-item .date-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 75px;
  text-align: right;
}
.application-item .date-wrapper .date {
  font-size: 0.8rem;
  font-weight: 400;
  color: #999999;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  line-height: 1;
}
.applications-grid {
  display: flex;
  align-items: flex-start;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  padding-top: 2rem;
  overflow-x: auto;
}
.application-item.grid-layout .info-wrapper {
  flex-direction: column;
}
.application-item.grid-layout .info {
  padding-right: 0px;
}
.application-item.grid-layout .date-wrapper {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
}
.application-item.grid-layout .date-wrapper .date {
  color: #cdcdcd;
}
.application-item.grid-layout .info-wrapper .title {
  font-size: 0.88rem;
}
.grid-item {
  height: 100%;
  padding: 8px;
  border-radius: 4px;
  padding-bottom: 30px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 310px;
  padding: 0 0.4rem;
}
.container {
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.main {
  margin-top: 60px;
  overflow: hidden;
}
.col-layout-wrapper {
  display: flex;
}
.col-layout-wrapper .sidebar {
  max-width: 300px;
  min-height: calc(100vh - 60px);
  flex: 1;
  border-right: 1px solid #dfdfdf;
}
.col-layout-wrapper .content-wrapper {
  flex: 2;
  padding: 0 2.5% 3.5%;
  max-height: calc(100vh - 60px);
  overflow: auto;
}
.navbar {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: space-between;
  align-items: center;
}
.logo-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-right: 2rem;
  flex-grow: 0;
  flex-basis: 0;
  min-width: 285px;
  border-right: 1px solid #dfdfdf;
}
.logo-wrapper img {
  display: block;
  max-width: 100%;
  height: 100%;
  max-height: 60px;
  cursor: pointer;
}
.hamburger-menu-btn {
  display: none;
}
.responsive-nav {
  display: block;
}
.nav-links-wrapper {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-links-wrapper li {
  padding: 0 1rem;
}
.nav-links-wrapper li a {
  text-decoration: none;
  padding: 0.2rem 0.15rem;
  display: block;
  font-weight: 600;
}
.header-broker-agency {
  font-size: 14px;
  padding-right: 10px;
  max-width: 275px;
  text-align: right;
}
.broker-agency-popup {
  margin-top: 10px;
}
.broker-agency-popup p {
  font-size: 14px;
}
.quote-select-dropdown {
  min-width: 220px;
}
.quote-select-dropdown .MuiPaper-elevation1 > * {
  font-size: 14px;
}
.profileModal,
.global-filter-dropdown {
  position: absolute;
  right: 10px;
  max-width: 200px;
  background: #fff;
  box-shadow: 0px 4px 20px 2px rgb(0 0 0 / 15%);
  border-radius: 4px;
  z-index: 1100;
  padding: 1rem;
  width: 100%;
}
.profileModal {
  min-width: 280px;
}
.global-filter-wrapper {
  position: relative;
}
.global-filter-dropdown {
  left: 66px;
  width: 280px;
}
.global-filter-dropdown ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.global-filter-dropdown ul li {
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 4px;
}
.global-filter-dropdown ul li:hover {
  background-color: #f7f7f7;
}
.global-filter-dropdown ul li div:last-child {
  height: 30px;
}
.global-filter-dropdown ul li svg {
  font-size: 23px;
}
.global-filter-handle {
  cursor: pointer;
}
.scan-progress {
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 10px;
}
.scan-progress .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
  margin-right: 10px;
}
.date-input {
  font-size: 13px;
  font-weight: 600;
  padding: 5px;
  background-color: #f7f7f7;
  border-radius: 4px;
}
.scan-verdict-wrapper .default-card {
  padding: 1rem 1.5rem;
}
.date-input .MuiFormControl-root {
  margin: 0;
}
.date-input .MuiFormHelperText-root {
  font-size: 11px;
}
.date-input input {
  font-size: 11px;
  width: 115px;
}
.myapplication-btn {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px !important;
  box-shadow: none !important;
  width: 154px;
  line-height: 25px !important;
}
.landing-page-wrapper {
  margin-top: 76px;
  min-height: calc(100vh - 76px);
}
.landingpageFormWrapper .MuiFormControl-root {
  max-width: 450px;
  margin-right: 10px;
  margin-top: 0;
}
.landingpageFormWrapper button {
  height: 56px;
  line-height: 30px;
  font-weight: 600;
  padding-right: 25px;
  padding-left: 25px;
}
.landingpageFormWrapper label {
  color: #999999;
}
.landing-inner-wrap form {
  width: 100%;
}
.landing-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
}
.btn-view-all-application {
  margin-bottom: 8%;
}
.waratah-logo {
  max-width: 350px;
  margin: 0 auto;
  opacity: 0.5;
}
.devider {
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 8%;
  width: 100%;
}
.devider span {
  display: inline-block;
  padding: 0 10px;
  background: #fff;
  position: relative;
  top: 10px;
  color: #999999;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 20px;
  font-size: 13px;
  font-weight: 600;
  z-index: 99;
}
.add-more-contact-btn {
  font-size: 13px !important;
  border: 0 !important;
}
.application-header-btns-wrapper {
  display: flex;
  align-items: flex-start;
  padding-left: 0 !important;
}
.application-header-btns-wrapper .back-home-btn {
  border-radius: 4px;
  padding: 0.2rem;
  margin-right: 15px;
}
.remove-contacts-block {
  width: 100%;
  justify-content: flex-end;
}
.fullScreen-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 90%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullScreen-loading-wrapper img {
  max-width: 100%;
  width: 350px;
  height: auto;
}
.fullScreen-loading-wrapper .progressBar {
  background-color: rgb(178, 240, 237) !important;
}
.fullScreen-loading-wrapper .progressBar > div {
  background-color: #36dad0 !important;
}
.stepsLoader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.stepsLoader .step-item > span:last-child {
  position: relative;
  top: 10px;
}
.steps-seperator {
  margin: 0 5px;
  position: relative;
  top: -2px;
}
.input-disabled {
  opacity: 0.5;
}
.input-disabled input {
  cursor: not-allowed;
}
.form-secondary-title {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  color: #666 !important;
  margin: 0;
}
button.form-action-btn {
  background-color: #36dad0;
}
button.form-action-btn:hover {
  background-color: #259891;
}
.page404-content {
  margin-top: 100px;
  text-align: center;
}
.page404-content h1 {
  font-size: 11rem;
  font-weight: 700;
  margin-bottom: 0;
}
.page404-content h3 {
  font-size: 2rem;
  letter-spacing: 0.3rem;
  margin: 0;
  color: #ccc;
}
.page404-content a {
  font-size: 14px;
  font-weight: 600;
}
.bind-status-icons {
  width: 45px;
  height: 45px;
}
.signature-icon {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 3px;
}
.signature-icon img {
  max-width: 100%;
  width: 23px;
  height: auto;
}
.signature-icon svg {
  font-size: 23px;
  opacity: 0.75;
}
.claim-url {
  display: inline-block;
  color: #5e5f61;
}
.copy-policy-number {
  width: 16px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 2px;
  height: 20px;
}
.copy-policy-number img {
  max-width: 100%;
  height: auto;
  opacity: 0.45;
}
.claim-url:hover,
.copy-policy-number:hover {
  opacity: 0.65;
}
.button-group {
  overflow: hidden;
}
.button-group button {
  margin: 2px 5px;
}
.button-group > div {
  margin: 0 -5px;
}
.username-text {
  word-break: break-word;
}
.btn-verdict {
  border: 1px solid #ccc !important;
  padding: 2px 8px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.json-pretty-style {
  font-size: 14px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
.application-watch-alert {
  z-index: 999 !important;
}
.sidebar-header {
  padding: 1rem 0.6rem;
  border-bottom: 1px solid #dfdfdf;
}
.application-stage-filter {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: space-between;
}
.application-stage-filter button {
  border-radius: 2px;
  padding: 0.4rem 0.55rem;
  border: 0;
  cursor: pointer;
  font-size: 0.65rem;
  text-transform: uppercase;
  font-weight: 600;
}
.application-stage-filter button:hover {
  opacity: 0.75;
}
.application-stage-filter .active button {
  background-color: #5e5f61;
  color: #fff;
}
.application-search .application-search-block {
  align-items: center;
  padding-top: 1rem;
}
.application-search .application-search-block > div {
  position: relative;
}
.application-search .application-search-block .clearBtn {
  position: absolute;
  right: 2px;
  top: 2px;
  opacity: 0.5;
}
.application-search .application-search-block .searchBtn {
  width: 46px;
  height: 34px;
  display: block;
  min-width: auto;
}
.application-search .application-search-block .searchBtn svg {
  font-size: 22px;
}
.application-search .application-search-block .search-field {
  margin: 0;
}
.application-search .application-search-block .search-field input {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.layout-header {
  padding-top: 20px;
}
.change-layout-btn {
  height: 25px;
  margin-right: 10px;
}
.change-layout-btn > div {
  display: flex;
  align-items: center;
}
.change-layout-btn svg {
  cursor: pointer;
  font-size: 28px;
}
.change-layout-btn svg.disabled {
  cursor: default;
}
.change-layout-btn svg.columnIcon {
  font-size: 30px;
}
.loadingJsonStyle {
  position: relative;
  overflow: hidden;
}
.loadingJsonStyle span .skeletonJsonLine {
  position: relative;
}
.loadingJsonStyle span:nth-child(1) .skeletonJsonLine {
  width: 15%;
}
.loadingJsonStyle span:nth-child(2) .skeletonJsonLine {
  width: 28%;
  left: 2.5%;
}
.loadingJsonStyle span:nth-child(3) .skeletonJsonLine {
  width: 25%;
  left: 2.5%;
}
.loadingJsonStyle span:nth-child(4) .skeletonJsonLine {
  width: 20%;
  left: 2.5%;
}
.loadingJsonStyle span:nth-child(5) .skeletonJsonLine {
  width: 30%;
  left: 5%;
}
.loadingJsonStyle span:nth-child(6) .skeletonJsonLine {
  width: 15%;
  left: 5%;
}
.loadingJsonStyle span:nth-child(7) .skeletonJsonLine {
  width: 20%;
  left: 2.5%;
}
.default-card {
  padding: 3.5rem;
}
.scan-alert {
  margin: 8px 0;
  min-height: 45px;
}
.scan-alert .MuiAlert-root {
  flex-wrap: nowrap;
  justify-content: left;
}
.scan-alert .MuiAlert-root .MuiAlert-message span {
  display: block;
  font-weight: 500;
  font-size: 14px;
}
.modalWrapper .MuiDialog-paper {
  margin: 8px;
}
.modalWrapper .MuiDialog-paperFullWidth {
  width: calc(100% - 16px);
}
.modalWrapper .MuiDialog-paperScrollPaper {
  max-height: calc(100% - 16px);
}
.modalWrapper .modal-header {
  border-bottom: 1px solid #e6e6e6;
  padding: 8px 24px;
  overflow-x: hidden !important;
}
.modalWrapper .MuiDialogContent-root {
  max-height: 80vh;
  overflow-x: hidden !important;
  min-height: 70vh;
}
.modalWrapper .modal-title {
  margin: 12px 0;
}
.modalWrapper .modal-close {
  position: relative;
  right: -15px;
  padding: 10px;
}
.bind-status-card .default-card {
  padding: 1rem;
}
.sidebar-handle {
  display: none;
}
.application-summary-card {
  height: 100%;
}
.application-summary-card .default-card,
.paymentBtnBlock .default-card {
  padding: 2.5rem;
}
@media (max-width: 1199px) {
  .application-header > div:nth-child(1) {
    max-width: 25%;
    flex-basis: 25%;
  }
  .application-header > div:nth-child(2) {
    max-width: 75%;
    flex-basis: 75%;
  }
  .searchFormWrapper input {
    min-width: 260px;
  }
  .default-card {
    padding: 2.8rem;
  }
  .default-card .default-card {
    padding: 2rem 1rem;
  }
  .bind-status-card .default-card,
  .scan-verdict-wrapper .default-card {
    padding: 1rem;
  }
  .application-item .info-wrapper .title {
    font-size: 0.85rem;
  }
}
@media (max-width: 960px) {
  .application-header {
    display: block;
  }
  .application-header > div:nth-child(1),
  .application-header > div:nth-child(2) {
    max-width: none;
    width: 100%;
  }
  .tenant-details-wrapper,
  .tenant-card {
    padding: 15px;
  }
  .tenant-form-wrapper {
    padding: 1.5rem;
  }
  .table-style td,
  .table-style th {
    padding: 10px;
  }
  .application-item .info-wrapper {
    flex-direction: column;
  }
  .application-item .info-wrapper .info {
    padding-right: 0px;
  }
  .application-item .date-wrapper .date:first-child {
    padding-right: 10px;
  }
  .application-item .date-wrapper {
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
  }
  .date-fields-wrapper > div:nth-child(1) {
    margin-bottom: 1rem;
  }
  .default-card {
    padding: 1.8rem;
  }
  .modalWrapper .MuiDialogContent-root,
  .modalWrapper .modal-header {
    padding: 8px 12px;
  }
  .bind-status-card .default-card,
  .scan-verdict-wrapper .default-card {
    padding: 1rem;
  }
  .landing-page-wrapper {
    flex-direction: column-reverse;
  }
  .application-header > div {
    padding-left: 0;
    padding-right: 0;
  }
  .col-layout-wrapper .sidebar {
    position: fixed;
    background: #fff;
    z-index: 99;
    transition: all 0.5s;
    transform: translatex(-300px);
    box-shadow: none;
  }
  .col-layout-wrapper .sidebar.active {
    transform: translatex(0px);
    -webkit-box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .sidebar-handle {
    width: 35px;
    height: 30px;
    position: absolute;
    right: -35px;
    padding: 2px 5px;
    display: block;
    top: 14px;
    -webkit-box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 5px 20px 0px rgba(0, 0, 0, 0.1);
  }
  .sidebar-handle svg {
    font-size: 25px;
  }
  .layout-header {
    padding-top: 60px;
  }
  /* .col-layout-wrapper .sidebar::before {
    content: "";
    background-color: #424242;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 89;
    top: 0;
    left: 0;
  } */
}
@media (max-width: 768px) {
  .application-header {
    display: block;
  }
  .application-header-actions {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .application-header-actions > div {
    flex-basis: 100%;
    width: 100%;
  }
  .searchFormWrapper input {
    min-width: auto;
    width: 100%;
  }
  .tenant-content-wrapper {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
  .tenant-content-wrapper > div:nth-child(1) {
    min-width: auto;
    width: 100%;
    max-width: none;
  }
  .applications-grid {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .grid-item {
    padding: 0 0.4rem 0.8rem;
  }
  .navbar {
    justify-content: flex-end;
  }
  .hamburger-menu-btn {
    display: block;
  }
  .responsive-nav {
    display: none;
    position: absolute;
    width: 100%;
    right: 0;
    background: #fff;
    top: 60px;
    text-align: right;
    border-top: 1px solid #e0e0e0;
    padding: 1rem;
  }
  .responsive-nav.active {
    display: block;
    box-shadow: 0px 10px 20px rgb(33 33 33 / 15%);
  }
  .nav-links-wrapper {
    flex-direction: column;
  }
  .nav-links-wrapper li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .nav-links-wrapper li a {
    border: 0 !important;
  }
  .logo-wrapper {
    padding-right: 0;
    min-width: max-content;
    border: none;
    margin-right: 15px;
  }
  .default-card .default-card {
    padding: 2rem 1rem;
  }
  .bind-status-card .default-card,
  .scan-verdict-wrapper .default-card {
    padding: 1rem;
  }
  .header-broker-agency {
    display: none;
  }
}
@media (max-width: 599px) {
  .application-summary-card .MuiTypography-root {
    text-align: left !important;
    margin-bottom: 5px;
  }
  .application-summary-card .MuiTypography-root.MuiTypography-alignRight {
    margin-bottom: 1rem;
  }
  .application-summary-card .custom-space {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
}
@media (max-width: 480px) {
  .tenant-details-wrapper {
    padding: 10px;
  }
  .tenant-form-wrapper {
    padding: 1rem;
  }
  .default-card {
    padding: 2rem 1rem;
  }
  .scan-alert .MuiAlert-root .MuiAlert-action {
    margin: auto;
  }
  .bind-status-card .default-card,
  .scan-verdict-wrapper .default-card {
    padding: 1rem;
  }
  .bind-status-card .MuiGrid-justify-content-xs-center {
    justify-content: flex-start !important;
  }
}
.text-ellipsis {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  font-weight: 600;
  width: 250px;
  height: 20px;
  margin-top: 4px;
  transition: all 0.2s ease;
  overflow: hidden;
}
.profile-tnc-wrapper {
  margin-top: 10px;
}
.profile-tnc-content {
  display: inline-block;
  width: calc(100% - 40px);
  vertical-align: top;
  margin-top: 5px;
}
.profile-tnc-content p {
  margin-top: 0;
  font-weight: 600;
}
.profile-tnc-content p {
  color: #666;
  font-size: 13px;
}
.profile-tnc-content .tnc-text {
  font-size: 14px;
}
.profile-tnc-content p span {
  color: #000;
}
.MuiAlert-root.tnc-alert-warning {
  padding: 1px 10px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}
.data-verify-checkbox .MuiSvgIcon-root {
  width: 22px;
  height: 22px;
}
.data-verify-checkbox > label {
  font-size: 14px;
}
.text-ellipsis.showmore {
  height: auto;
  width: 100%;
}
.text-ellipsis.showmore p {
  white-space: unset;
}
.readmore-btn {
  font-size: 13px;
  font-weight: 600;
  opacity: 0.7;
  cursor: pointer;
  position: relative;
  top: 2px;
}
.readmore-btn:hover {
  opacity: 1;
}
.profile-contact-details-row {
  display: flex;
  width: 100%;
  position: relative;
}
.profile-contact-details-row > .MuiGrid-root {
  padding: 0 12px;
}
.profile-contact-details-row button {
  position: absolute;
  right: -35px;
  top: 10px;
  color: #f00;
}
.profile-contact-details-row svg {
  font-size: 1.2rem;
  width: 23px;
  height: 25px;
}
.contact-group-label {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 15px 0 5px;
  padding: 0 12px;
}
.contact-group-label span {
  display: inline-block;
  background: #fff;
  padding-right: 10px;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 600;
  color: #999;
}
.contact-group-label:after {
  content: "";
  position: absolute;
  left: 12px;
  top: 13px;
  width: calc(100% - 24px);
  height: 1px;
  background: #ccc;
}
.MuiButton-root.contact-group-add-btn {
  margin: 10px 0 20px 12px;
  padding: 2px 10px;
  font-size: 13px;
  background-color: #efefef;
}
.MuiButton-root.contact-group-add-btn:hover {
  background-color: #fff;
}
.MuiButton-root.contact-group-add-btn svg {
  font-size: 12px;
}
.MuiButton-root.contact-group-add-btn .MuiButton-startIcon {
  margin-right: 4px;
}
.hide {
  display: none !important;
}
.error-text {
  color: #f44336;
  margin: 0;
  font-size: 0.8571428571428571rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  margin-left: 14px;
  margin-right: 14px;
}
.confirmation-modal-title h2 {
  font-weight: 700;
}
.confirmation-modal-content.MuiTypography-body1 {
  font-size: 16px;
}
.confirm-btn.danger {
  color: rgb(244, 67, 54);
}
